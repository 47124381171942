<template>
    <div class="main-container">
        <div class="title">Ingresa a los juegos de inteligencia cramerianos</div>
        <div class="login-container">
            <img 
                src="../assets/escudo-cramer.png" 
                alt="escudo cramer" 
                class="logo-login mb-5"
            >
            <form class="form-login" @submit.prevent="validate()" >
                <div class="form__input-label-container mt-5">
                    <label for="user">Correo institucional</label>
                    <input placeholder="user@cramerpuno.edu.pe" v-model="user" type="email" id="user" name="user">
                    <div class="error-text">
                        {{errorEmail}}
                    </div>
                </div>
                <div class="form__input-label-container mt-5">
                    <label for="password">Constraseña</label>
                    <input v-model="password" type="password" id="password" name="password">
                    <div class="error-text">
                        {{errorPassword}}
                    </div>
                </div>
                <div class="form__input-label-container mt-5">
                    <label for="password">Repite constraseña</label>
                    <input v-model="repassword" type="password" name="password">
                    <div class="error-text">
                        {{errorRePassword}}
                    </div>
                </div>
                <div v-if="errorUser" class="error-text" style="margin-top:1em;font-size:1.2em;">
                        {{errorUser}}
                    </div>
                <button class="login-button mt-5" type="submit">Crear cuenta</button>
            </form>
            <router-link to="/login" class="btn-back">
                            Regresar
            </router-link>
        </div>
        <img class="login-lion" src="../assets/characters/teacher-cramer.png">
    </div>
</template>
<script>
import {auth,db} from '../firebase'
export default {
    data:()=>({
        user:'',
        password:'',
        repassword:'',
        errorEmail:'',
        errorPassword:'',
        errorRePassword:'',
        errorUser:''
    }),
    methods:{
        validate(){
            this.errorEmail='';
            this.errorPassword='';
            this.errorRePassword='';
            if(!this.user)
            {
                this.errorEmail="Ingrese un correo por favor";
            }
            else if(!this.password)
            {
                this.errorPassword="Ingrese una contraseña por favor";
            }
            else if(!this.repassword)
            {
                this.errorRePassword = "Repita la contraseña por favor";
            }
            else{
                if(!this.validEmail(this.user))
                {
                    this.errorEmail="Ingrese un correo @cramer.edu.pe por favor"
                }
                else{
                    if(this.password.length<6)
                    {
                        this.errorPassword="Ingrese una contraseña con almenos 6 caracteres";
                    }
                    else{
                        if(this.password!=this.repassword)
                        {
                            this.errorRePassword='Las contraseñas no coinciden';
                        }
                        else{
                            this.register()
                        }
                    }
                }
            }
        },
        validEmail(email) {
            let re = /\w+@cramerpuno\.edu\.pe/;
            return re.test(email);
        },
        register()
        {
            auth.createUserWithEmailAndPassword(this.user, this.password)
            .then((user) => {
                this.saveUserData(user.user.uid)
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode)
                console.log(errorMessage)
                this.errorUser="El usuario ya existe"
                // ..
            });
            
        },
        saveUserData(id){
            db.collection("usuarios").doc(id).set({
                nombres:'',
                paterno:'',
                materno:'',
                nivel:'',
                grado:'',
                seccion:'',
                avatar:'https://res.cloudinary.com/juegos-cramer/image/upload/v1616176603/juegos-cramer/man_icon-icons.com_55040_a2fdk5.svg'
            })
            .then(() => {
                this.$router.replace('/login');
                
            })
            .catch((error) => {
                this.errorUser="Error inesperado :c"
                console.error("Error writing document: ", error);
            });
        }
    }
}
</script>
<style scoped>
.title{
    display:none;
}
.login-lion{
    display: none;
}
.login-container{
    background-color:#050259;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/bg-movil/bg-login.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.logo-login{
    width: 40vh;
}
.form-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}
.form__input-label-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.form__input-label-container label{
    color: white;
    font-size: 25px;
}
.form__input-label-container input{
    padding: .5em .7em;
    border-radius:5px;
    border:white solid 1px;
    width: 94%;
}
.login-button{
    background-color:#F23827;
    border: white solid 1px;
    color:white;
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 30px;
    width: 100%;
    border-radius:10px;
}
.mt-5{
    margin-top:20px;
}
.mb-5{
    margin-bottom: 20px;
}
.login-button:hover{
    cursor: pointer;
}
input:focus, input.form-control:focus,button {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.btn-back{
    color:white;
    padding: 0.2em 0em;
    width: 90%;
    margin-top:1em;
    border-radius: 10px;
    text-align: center;
    font-size: 1.5em;
    border:1px white solid;
    text-decoration: none;
    background-color: rgb(149, 144, 149);
}
.error-text{
    color:tomato;
}
@media (min-width: 900px) and (orientation: landscape) {
    .login-container{
        background-color: #050259;
        box-shadow: white 3px 3px 10px;
    }
    .main-container{
        display: grid;
        grid-template-columns: 10% 20% 20% 10% 20% 20%;
        grid-template-rows: 10vh 80vh 10vh;
        background-color: #050259;
        background-image: url('../assets/bg-desktop/bg-login.svg');
        background-position-x: -10px;
        background-position-y: -500px;
    }
    .login-lion{
        display: block;
        width: 70vh;
        transform: translateY(10%);
        grid-column: 4 / 4;
        grid-row: 2 / 2;
    }
    .login-container{
        height: 80vh;
        grid-column: 2 / 2;
        grid-row:2 / 2;
    }
    .logo-login{
        width: 30vh;
    }
    .title{
        text-align: center;
        display: block;
        font-size: 3em;
        grid-column: 3 / 7;
        transform: translateY(40%);
        color:white;
    }
}
</style>